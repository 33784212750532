import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const ProWelcome = () => (
    <Layout hideHeader={true} hideFooter={true}>
        <SEO title="Galaxy Pro Welcome" />
        <section className="hero is-white is-fullheight">
            <div className="hero-body">
                <div className="container">
                    <div className="columns">
                        {/* <div className="column is-two-fifths"> */}
                        <div className="column">
                            {/* </div>
                        <div className="column"> */}
                            <div className="container m-l-lg">
                                <h1 className="title alternative is-1">
                                    GALAXY <span className="has-text-warning">PRO</span>
                                </h1>
                                {/* <p>Instant access to data analysis pipelines.</p>
                                <p>Exploratory analysis with validated tools.</p>
                                <p>Professional services to ensure customer success.</p> */}
                                {/* <br /> */}
                                <br />
                                {/* <h2 className="title">Explore your Data</h2> */}
                                <h2 className="subtitle">Explore your Data</h2>
                                <div>
                                    <p>
                                        Your Galaxy Pro instance is ready for use. Start by uploading data and selecting
                                        tools from the tool panel on the left. You can also run complex pipelines via
                                        workflows or share data with colleagues and collaborators.
                                    </p>
                                    <h2 className="subtitle m-t-lg">Features</h2>
                                    <ul className="m-l-lg" style={{ listStyleType: "square" }}>
                                        <li>
                                            Access a comprehensive suite of bioinformatics tools tailored for
                                            high-throughput genomic analysis
                                        </li>
                                        <li>
                                            Leverage the scalability of your private server to handle datasets and
                                            workloads of any size with confidence
                                        </li>
                                        <li>
                                            Enjoy the flexibility of a continually improving framework that evolves with
                                            your research needs
                                        </li>
                                    </ul>

                                    <h2 className="subtitle m-t-lg">Support</h2>
                                    <p className="m-t-lg">
                                        How can we help? Reach out to us with any questions at{" "}
                                        <a className="has-text-weight-bold" href="mailto:support@galaxyworks.io">
                                            support@galaxyworks.io
                                        </a>
                                        .
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
);

export default ProWelcome;
